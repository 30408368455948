import { IShippingService } from '../lib/shippingService'
import { ShippingFormActionType } from '../constants/shippingFormAction'
import { AssetsProvider } from '@jarvis/web-assets-provider'
import { BaseURLProvider } from '@jarvis/web-stratus-client'
import { JarvisAuthProvider } from '@jarvis/web-http'
import { AsyncAction, AsyncDispatch } from '../hooks/useAsyncReducer'
import { SemanticsExtended } from '@veneer/semantics'
import { ThemeContextInterface } from '@veneer/theme'

export { ContainerSize } from './containerSize'
export { WindowSize } from './windowSize'

export type ShippingFormInitialState = {
  language?: string
  country?: string
  baseURLProvider?: BaseURLProvider
  authProvider?: JarvisAuthProvider
  xCorrelationId?: string
  mockStratus?: boolean
  onSave?: () => void
  onSaveButtonClick?: onSaveButtonClick
  onCancel?: () => void
  cloudId?: string
  addressId?: string
  trackClickEvent?: trackClickEvent
  saveButtonText?: string
  hideTitle?: boolean
  secondaryButton?: HTMLElement
  disableListView?: boolean
  enableDebugging?: boolean
  doNotEnableInkDeliveryButton?: HTMLElement
  enableShippingAutofill?: boolean
  shippingFromAutofill?: shippingFromAutofillData
}

export enum ShippingFormDataField {
  id = 'id',
  firstName = 'firstName',
  lastName = 'lastName',
  company = 'company',
  phoneNumber1 = 'phoneNumber1',
  street1 = 'street1',
  street2 = 'street2',
  city = 'city',
  state = 'state',
  zipCode = 'zipCode',
  country = 'country'
}

export type ShippingFormData = {
  tenantClassification: string
  supportMultiShipping: string
  id?: string
  street1: string
  street2?: string
  city: string
  state?: string
  fullState?: string
  zipCode: string
  countryCode: string
  firstName: string
  lastName: string
  fullName: string
  company?: string
  phoneNumber1: string
  optedIn: boolean
  isDefaultAddress: boolean
  allowPoBox: boolean
}

export type AutofillShippingFormData = {
  street1: string
  city: string
  zipCode: string
  state: string
  countryCode: string
  street2?: string
}

export type shippingFromAutofillData = {
  Address: string
  City: string
  ZipCode: string
  State: string
  Country: string
}

export type SettingsData = {
  criticalScopesModalTimeout: number
  enableCanaryIslands: boolean
  enableNonWarrantyReplacementLink: boolean
  enablePgsHostedFormV2: boolean
  enableRebrandedHome: boolean
  enableRebranding: boolean
  enableSessionExpiredModal: boolean
  enableStreetCharacterLimitErrors: boolean
  enableSmbPoBoxExclusion: boolean
  enableTextMessageOptin: boolean
  merchantName: string
  newPaymentSwitcherStrings: boolean
  showNonEditableBilling: boolean
  showPrepaid: boolean
  showReusableBillingForm: boolean
  showShippingAccountType: boolean
  showShippingTaxId: boolean
  systemTenantId: string
  twoCheckoutEnrollmentProductId: string
  twoCheckoutMerchantId: string
  twoCheckoutTestMode: boolean
  useStratusV2Template: boolean
}

export type ShippingFormState = {
  shippingService: IShippingService
  errorFields: Set<string>
  warningFields: Set<string>
  enableErrors: boolean
  showSuggestedAddressModal: boolean
  showUnsupportedPostalCodeModal: boolean
  showUnsupportedPaperAddressModal: boolean
  suggestedAddress?: ShippingFormData
  selectedAddress?: ShippingFormData
  fieldErrorCode?: string
  formErrorCode?: string
  assetsProvider?: AssetsProvider
  country?: string
  shippingData?: ShippingFormData
  shippingDataLoading?: boolean
  shippingDataError?: Record<string, unknown>
  settingsData?: SettingsData
  settingsDataLoading?: boolean
  settingsDataError?: Record<string, unknown>
  validatedAddress?: ShippingFormData
  onSave?: onSave
  onSaveButtonClick?: onSaveButtonClick
  onCancel?: () => void
  fetchErrors: Dispatchable[]
  cloudId: string
  addressId: string
  shippingAddresses?: Array<ShippingFormData>
  shippingAddressesLoading?: boolean
  shippingAddressesError?: Record<string, unknown>
  hasExistingAddress?: boolean
  addressCount: number
  isDefaultAddress?: boolean
  fields: Set<string>
  trackClickEvent?: trackClickEvent
  saveButtonText?: string
  hideTitle?: boolean
  secondaryButton?: HTMLElement
  disableListView?: boolean
  enableDebugging?: boolean
  doNotEnableInkDeliveryButton?: HTMLElement
  enableShippingAutofill?: boolean
  shippingFromAutofill?: shippingFromAutofillData
}

export type onSave = (error?: OnSaveError, data?: OnSaveData) => void

export type onSaveButtonClick = (event?: unknown) => void

export type trackClickEvent = (linkId: string, linkPlacement: string) => void

export type ShippingFormAction = {
  type: ShippingFormActionType
  field?: string
  value?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data?: any
  error?: Record<string, unknown>
  fieldErrorCode?: string
  formErrorCode?: string
  errorFields?: Set<string>
  warningFields?: Set<string>
  language?: string
  country?: string
  fields?: Set<string>
  options?: ValidateFieldOptions
}

export type ValidateFieldOptions = {
  accountType?: string
  blockPoBox?: boolean
  isSmb?: boolean
}

export type Dispatchable = ShippingFormAction | ShippingFormDispatchHandler

export type DispatchableFunction = () => Dispatchable

export type DispatchFunction = AsyncDispatch<
  ShippingFormAction,
  ShippingFormState
>

export type ShippingFormDispatchHandler = AsyncAction<
  ShippingFormAction,
  ShippingFormState
>

export enum OnSaveError {
  expiredCriticalScope = 'expired_critical_scope',
  expiredToken = 'expired_token'
}

export type OnSaveData = {
  addressId?: string
}

export type ThemeSettings = {
  customSemantics: SemanticsExtended
  mode: ThemeContextInterface['mode']
  shape: ThemeContextInterface['shape']
  density: ThemeContextInterface['density']
}
